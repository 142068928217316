import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Register, Login, ForgotPasswordAPI } from "../api-caller/apiCaller";
import { withRouter } from "react-router-dom";
import Header from "./Header";
import "../../css/scss/LoginForm.scss";
class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      isSubmitting: false,
    };
    this.changeState = this.changeState.bind(this);
  }
  changeState(stateInfo) {
    this.setState(stateInfo);
    this.setState({ isSubmitting: false });
  }
  render() {
    console.log("render login");
    return (
      <div className="Logincard">
        <Header />
        <div className="InnerCard">
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, formikActions) => {
              this.setState({ isSubmitting: true });
              // console.log('inside submit ', values, formikActions);
              Login(
                {
                  EmailAddress: values.email,
                  Password: values.password,
                },
                {
                  changeState: this.changeState,
                  resetForm: formikActions.resetForm,
                  props: this.props,
                }
              );
              formikActions.setSubmitting(false);
            }}
            validationSchema={GetLoginVaidationSchema}
          >
            <Form className="form-container">
              {this.state.formError ? this.state.formError : null}
              <div className="form-group ">
                <label htmlFor="email">
                  Email
                  <sup>
                    <span className="star-color pl-lg-1">.</span>
                  </sup>
                </label>
                <Field
                  type="text"
                  placeholder="Email Addresss"
                  autoComplete="false"
                  name="email"
                ></Field>
                <div className="errorbox">
                  <ErrorMessage name="email" className="errorDiv" />
                </div>
              </div>
              <div className="form-group">
                <span>
                  <label
                    htmlFor="password "
                    style={{
                      color: " #454F5B",
                      fontWeight: "bold",
                    }}
                  >
                    Password
                    <sup>
                      <span className="star-color pl-lg-1">.</span>
                    </sup>
                  </label>
                  <a
                    className="float-right "
                    href="#"
                    onClick={() => this.props.history.push("/forgotPassword")}
                  >
                    Forgot password?
                  </a>
                </span>

                <Field
                  autoComplete="new-password"
                  type="password"
                  placeholder="Password"
                  name="password"
                ></Field>
                <div className="errorbox">
                  <ErrorMessage name="password" className="errorDiv" />
                </div>
              </div>
              {/* <label className='mb-4'>
              <input type='checkbox' name='remember' /> Remember me
            </label> */}

              <div className="mt-4">
                <button
                  type="submit"
                  disabled={this.state.isSubmitting}
                  className="fbtn   golden-button"
                >
                  {this.state.isSubmitting ? "Loading..." : "Login"}
                </button>
              </div>
              <div className="text-center w-100 mt-4">
                Don’t have an Account?
                <span
                  className="golden-text"
                  onClick={() => {
                    this.props.history.push("/signup");
                  }}
                >
                  Sign Up
                </span>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    );
  }
}
export default withRouter(LoginForm);

function GetLoginVaidationSchema() {
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Required"),
    password: yup
      .string()
      .min(4, "Please choose a password with at least 4 characters ")
      .required("Required"),
  });
  // console.log('vlaidlogin', validationSchema);
  return validationSchema;
}
