import React from "react";
import { withRouter } from "react-router-dom";
import success from "../../images/success.svg";
import utility from "../api-caller/utility";
import "./thankyou.css";

class KYCThankYou extends React.Component {
  componentDidMount() {
    let leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    const leadP = !!leadId;
    // if session expire redired user to login page
    if (!leadP) {
      // console.log("auth session already expired ");
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="application-success-wrapper">
        <img src={success} alt="success" />
        <p>Your KYC Documents Has Been Submitted Successfully</p>
        <div className="button-wrapper">
          <a href="/Dashboard" className="btn btn-primary">
            Go To Dashboard
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(KYCThankYou);
