import React from 'react';
import 'bootstrap';
function ErrorAlert(text, type) {
  return (
    <div
      className={
        type === 'danger'
          ? 'alert alert-danger alert-dismissible fade show text-center'
          : 'alert alert-success alert-dismissible fade show text-center'
      }
      role='alert'>
      {/* <button
        type='button'
        className='close'
        data-dismiss='alert'
        aria-label='Close'>
        <span aria-hidden='true'>&times;</span>
      </button> */}
      {text}
    </div>
  );
}
export { ErrorAlert };
