import Constants from "../constants/index";
import config from "../config/index";
import utilityFunction from "./utility";
import { ErrorAlert } from "../alert/Alert";
import axios from "axios";
import "bootstrap";

let lsqApiInstance = axios.create({
  baseURL: Constants.LSQAPI.baseURL,
  timeout: 10000,
  headers: { Authorization: utilityFunction.GetAuthKey() },
});

function fetchLeadByRefereeLeadId(leadId) {
  var data = {
    Parameter: {
      LookupName: "mx_ReferrerLeadID",
      LookupValue: leadId,
      SqlOperator: "=",
    },
    Columns: {
      Include_CSV:
        "ProspectID, FirstName, LastName, EmailAddress, mx_Project_Name, mx_House_Type, ProspectStage,Mobile",
    },
    Sorting: {
      ColumnName: "CreatedOn",
      Direction: "1",
    },
    Paging: {
      PageIndex: 1,
      PageSize: 100,
    },
  };
  fetch(
    Constants.LSQAPI.baseURL +
      Constants.LSQAPI.endPoints.GetLeadByFieldDetail +
      "?accessKey=" +
      config.AccessKey +
      "&secretKey=" +
      config.SecretKey,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((Response) => Response.json())
    .then((json) => {
      //console.log("get lead details ", json);
      return json;
    });
}

function Register(data, form_props) {
  fetch(Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.Register, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: config.AuthKey,
    },
  })
    .then((Response) => Response.json())
    .then((json) => {
      //console.log(json);
      if (json.hasOwnProperty("Status") && json.Status === "Error") {
        let er = json.ExceptionMessage;

        form_props.changeState({
          formError: ErrorAlert(er, "danger"),
        });
      } else if (json.hasOwnProperty("LeadId")) {
        localStorage.clear();
        //console.log(json);

        form_props.changeState({
          formError: ErrorAlert(
            "Signup Successful. Please Login to Continue.",
            "success"
          ),
        });
        form_props.resetForm();
        let userData = {
          EmailAddress: data.LeadFields[1].Value,
          Password: data.LeadFields[2].Value,
        };
        Login(userData, form_props);
      } else {
        //console.log("Exception :", "Network disconnected");
        form_props.changeState({
          formError: ErrorAlert("Some Exception Occured", "danger"),
        });
      }
    })
    .catch((er) => {
      //console.log("Exception :", er);
      form_props.changeState({
        formError: ErrorAlert("Some Exception Occured", "danger"),
      });
    });
}
function Login(data, form_props) {
  //console.log("inside login", data, form_props);
  fetch(Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.Signin, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: config.AuthKey,
    },
  })
    .then((Response) => Response.json())
    .then((json) => {
      //console.log(json);
      if (json.hasOwnProperty("Status") && json.Status === "Error") {
        let er = json.ExceptionMessage;
        form_props.changeState({
          formError: ErrorAlert(er, "danger"),
        });
      } else if (json.hasOwnProperty("LeadId")) {
        API_GetLeadsById(json.LeadId)
          .then((data) => data.json())
          .then(function (data) {
            const userObject = {
              name: data[0].FirstName,
              email: data[0].EmailAddress,
              phone: data[0].Mobile,
              company: data[0].Company,
            };
            localStorage.clear();
            localStorage.setItem("userData", JSON.stringify(userObject));
            localStorage.setItem("username", data);
            var authSessionInfo = {
              isAuthenticated: true,
              leadId: json.LeadId,
              authKey: json.AuthKey,
            };
            //console.log("authSessionInfo ", authSessionInfo);
            utilityFunction.SetAuthSessionInfoCookie(authSessionInfo);
            // //console.log('cookie', document.cookie);

            form_props.changeState({
              formError: ErrorAlert(
                "Successfully Registered . Redirecting to Dashboard.",
                "success"
              ),
            });
            form_props.resetForm();

            form_props.props.history.push({
              pathname: "/Dashboard",
              search: "", // search: '?query=abc',
              state: { detail: json },
            });
          })
          .catch((error) => console.log(error, "error"));
      } else {
        // console.log("Exception :", "Network disconnected");
        form_props.changeState({
          formError: ErrorAlert("Some Exception Occured", "danger"),
        });
      }
    })
    .catch((er) => {
      // console.log("Exception :", er);
      form_props.changeState({
        formError: ErrorAlert("Some Exception Occured", "danger"),
      });
    });
}
function ForgotPasswordAPI(payload, form_props) {
  // console.log("Forgot password payload props", payload, form_props);
  fetch(
    Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.ForgotPassword,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: config.AuthKey,
      },
      method: "POST",
      body: JSON.stringify(payload),
    }
  )
    .then((response) => response.json())
    .then(function (response) {
      form_props.changeState({
        formError: ErrorAlert(
          "A Password reset link has been sent to " + payload.EmailAddress,
          "success"
        ),
      });
      form_props.resetForm();
    })
    .catch(function (error) {
      // console.log("Exception :", error);
      form_props.changeState({
        formError: ErrorAlert(error, "danger"),
      });
    });
}
function ResetPassword(payload, props) {
  // console.log("Reset password password payload props", payload, props);
  fetch(
    Constants.PortalAPI.baseURL + Constants.PortalAPI.endPoints.ResetPassword,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: config.AuthKey,
      },
    }
  )
    .then((json) => json.json())
    .then(function (json) {
      //console.log("reset password response", json);
      if (json.hasOwnProperty("Status") && json.Status === "Error") {
        props.changeState({
          formError: ErrorAlert(json.ExceptionMessage, "danger"),
        });
      } else {
        props.changeState({
          formError: ErrorAlert(
            "Your password has been reset successfully!",
            "success"
          ),
        });
        window.setTimeout(function () {
          props.props.history.push("/");
        }, 1500);
      }
    })
    .catch(function (error) {
      props.changeState({
        uError: ErrorAlert(
          "Sorry for inconvinence .Please try again",
          "danger"
        ),
      });
      //console.log("Exception :", error);
    });
}

const API_GetLeadsById = (payload) => {
  let url = new URL(
    Constants.LSQAPI.baseURL + Constants.LSQAPI.endPoints.GetLeadsById
  );
  let params = [
    ["id", payload],
    ["AccessKey", config.AccessKey],
    ["SecretKey", config.SecretKey],
  ];
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: config.AuthKey,
    },
    method: "GET",
  });
};

const API_GetLeadActivity = (payload) => {
  return lsqApiInstance({
    method: "GET",
    headers: "",
    url: Constants.LSQAPI.baseURL + Constants.LSQAPI.endPoints.GetLeadsById,
    params: {
      accessKey: config.AccessKey,
      secretKey: config.SecretKey,
      leadId: payload.leadId,
    },
  });
};

export {
  Register,
  Login,
  ForgotPasswordAPI,
  fetchLeadByRefereeLeadId,
  ResetPassword,
  API_GetLeadsById,
  API_GetLeadActivity,
};
