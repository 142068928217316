import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "./components/landing-page/LoginForm.js";
import SignUpForm from "./components/landing-page/SignUpForm.js";
import ForgotPassword from "./components/landing-page/ForgotPassword.js";
import ResetPassword from "./components/reset-password/ResetPassword";
import LandingPage from "./components/landing-page/LandingPage";
import utility from "./components/api-caller/utility";

class LandingRoute extends React.Component {
  constructor(props) {
    super(props);
    this.routes = [
      {
        path: "/signup",
        exact: true,
        component: SignUpForm,
        title: "SignUpForm",
      },
      {
        path: "/login",
        exact: true,
        component: LoginForm,
        title: "LoginForm",
      },
      {
        path: "/forgotPassword",
        exact: true,
        component: ForgotPassword,
        title: "forgotPassword",
      },
      {
        path: "/ResetPassword",
        exact: true,
        component: ResetPassword,
        title: "resetPassword",
      },
      {
        path: "/",
        exact: true,
        component: LandingPage,
        title: "LandingPage",
      },
    ];
  }

  componentDidMount() {
    const Auth = utility.GetAuthSessionInfoCookieValue("authSessionState")
      ? utility.GetAuthSessionInfoCookieValue("authSessionState")
          .isAuthenticated
      : false;
    if (Auth) {
      console.log(Auth, "Auth");
      this.props.history.push("/dashboard");
    }
  }

  isAllowed = (props, RouteComponent, title) => {
    var props1 = {};
    var newProps = { ...props, ...props1 };
    return <RouteComponent {...newProps} />;
  };

  render() {
    return (
      <Switch>
        {this.routes.map((route, i) => (
          <Route
            key={i}
            exact={route.exact}
            path={route.path}
            render={(props) =>
              this.isAllowed(props, route.component, route.title)
            }
          />
        ))}
        <Redirect to={"/"} />
      </Switch>
    );
  }
}

export default withRouter(LandingRoute);
