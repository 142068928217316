import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../Dashboard/Header";
import KYCForm from "./KYCForm";

const KYC = () => {
  return (
    <div style={{ backgroundColor: "#FAFCFF" }}>
      <Header />
      <KYCForm />
    </div>
  );
};

export default withRouter(KYC);
