import React from "react";
import $ from "jquery";
import utility from "../api-caller/utility";
import { withRouter } from "react-router-dom";
import utilityFunction from "../api-caller/utility";

import loading from "../../images/loading.gif";
/* global lsq_setupForm */
class RenderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadId: null,
      loading: true,
      userDetails: {},
    };

    var leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    // if session expire redired user to login page
    if (!leadId) {
      // console.log('auth session already expired ');
      this.props.history.push("/");
    } else {
      // console.log('sd', leadId);
      this.state.leadId = leadId;
    }
    this.showLoading = this.showLoading.bind(this);
    // console.log('Render component created');
    this.loadOverrideCSS = this.loadOverrideCSS.bind(this);
    this.onLSQFormSubmissionSuccess = this.onLSQFormSubmissionSuccess.bind(
      this
    );
  }
  loadOverrideCSS() {
    // var cssFile = document.createElement('link');
    // cssFile.setAttribute('rel', 'stylesheet');
    // cssFile.setAttribute('type', 'text/css');
    // cssFile.setAttribute('href', '/lsq-form-override.css');
    // document.getElementsByTagName('head')[0].appendChild(cssFile);
    const referrer = localStorage.getItem("userData");
    const userData = JSON.parse(referrer);
    // console.log(referrer, "referrer");
    $("head").append(
      '<link rel="stylesheet" type="text/css" href="/lsq-form-override.css">'
    );
    var date = new Date().toLocaleString();
    $('input[data-schemaname="mx_Custom_2"]').val(date);
    $('input[data-schemaname="mx_ReferrerLeadID"]').val(this.state.leadId);

    $('input[data-schemaname="mx_Referrer_Name"]').val(userData.name);
    $('input[data-schemaname="mx_Referrer_Phone"]').val(userData.phone);
    $('input[data-schemaname="mx_Referrer_Email"]').val(userData.email);
    $('input[data-schemaname="mx_Referrer_Company"]').val(userData.company);

    window
      .$(
        "#60ea569d-8aad-11ea-8109-022edb41208c__tab1__section1__mx_Custom_1mx_CustomObject_1__Activity__201"
      )
      .leadcombobox("setValueById", this.state.leadId);
    this.setState({ loading: false });
  }
  componentDidMount() {
    //authentication

    // console.log('leadid', this.state.leadId);
    var devFormurl =
      "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=61.01.0.5";

    $.getScript(devFormurl)
      .done(function (data) {
        lsq_setupForm({
          id: "60ea569d-8aad-11ea-8109-022edb41208c",
        });
      })
      .fail(function (error) {});

    this.lsqFormContainer.addEventListener(
      "lsqformloadcomplete",
      this.loadOverrideCSS
    );
    // this.lsqFormContainer.addEventListener(
    //   'lsqformsubmissionsuccessateachstep',
    //   this.onLSQFormSubmissionSuccessAtEachStep
    // );
    this.lsqFormContainer.addEventListener(
      "lsqformsubmissionsuccess",
      this.onLSQFormSubmissionSuccess
    );
    this.lsqFormContainer.addEventListener(
      "LSQFormLoadError",
      this.onLSQFormLoadError
    );
  }

  onLSQFormSubmissionSuccess() {
    // console.log('Form Submitted Successfully');
    // console.log(this.props);

    window.location.reload(true);
    // console.log('lead created');
  }
  onLSQFormSubmissionSuccessAtEachStep(e) {
    // console.log('saved lead');
  }
  onLSQFormLoadError(e) {
    //Expire cookie
    utilityFunction.ExpireAuthSessionInfoCookie();
    //log out
    window.location.href = "/";
  }

  showLoading(show) {
    if (show)
      return (
        <div className="loader-container">
          <img src={loading} className="loader" alt="LSQEstates" />
        </div>
      );
  }
  render() {
    // console.log(this.props);
    var actualAuthKey =
      "Z3BUWW9ONjRMK2xUWTZTUVdzRm5uRi85SjdmVC9wdmpQTk00ZGJpQzVpSmo2U0I3SkZiM2djK2tqb2Q0YVhVMk9jTTJoOFY0aWZrOERESXVCSnQ5NklxZklCS1Rxc01iaVFLaGdHb2lpRXl0OThxWHphL25WVkw2NDhlL1l0UUYxSjUzNXRiOE9KR01HcHc2cG02YWc5OGkyQUl0TDhKQTQ0Z004UjhGNk5iY3RFVzNDUTdyYWlvUFM1MHJ6MHdhUkduUERhL2NMaFpvaVJYQ0JIcWxVdz09";

    var actualFormId = "60ea569d-8aad-11ea-8109-022edb41208c";

    return (
      <div id="render-form">
        <div
          className=" d-flex justify-content-between"
          style={{ padding: "23px" }}
        >
          <div>Add New Referral</div>
          <i
            onClick={this.props.props.hideForm}
            className="fa fa-times float-right "
            style={{ fontSize: "24px", color: "#637381", cursor: "pointer" }}
            aria-hidden="true"
          ></i>
        </div>
        <div className="hr"></div>
        {/* render form (LSQ Form) */}
        <div
          id="lsq-form-modal"
          ref={(elem) => (this.lsqFormContainer = elem)}
          data-form-id={actualFormId}
          className=" modal-v4 fullscreen external lsq-external-form-container p-0 pt-0"
        >
          {this.showLoading(this.state.loading)}
          <div className="lsq-form-container-wrapper   pt-0  "></div>
          <div className="lsq-form-hidden-fields">
            <input
              id="lsq-authKey"
              name="lsq-authKey"
              type="hidden"
              value={actualAuthKey}
            />
            <input
              id="lsq-api-service-url"
              name="lsq-api-service-url"
              type="hidden"
              value="https://portalapi-in21.leadsquared.com/api/Form"
            />
            <input
              id="lsq-app-url"
              name="lsq-app-url"
              type="hidden"
              value="https://in21.leadsquared.com"
            />
          </div>
        </div>
        {/* LSQ Form End */}
      </div>
    );
  }
}
export default withRouter(RenderForm);
