import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Register, Login, ForgotPasswordAPI } from "../api-caller/apiCaller";
import { withRouter } from "react-router-dom";
import Header from "./Header";
import "../../css/scss/SignUpForm.scss";
class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      isSubmitting: false,
    };
    this.changeState = this.changeState.bind(this);
  }

  changeState(stateInfo) {
    this.setState(stateInfo);
    this.setState({ isSubmitting: false });
  }
  render() {
    // console.log("props", this.props);
    return (
      <div className="card">
        <Header />
        <div className="InnerCard">
          <Formik
            initialValues={{
              name: "",
              mobile: "",
              email: "",
              password: "",
              company: "",
            }}
            validationSchema={GetVaidationSchema}
            onSubmit={(values, formikActions) => {
              this.setState({ isSubmitting: true });
              // console.log('formick action ', formikActions.isSubmitting);
              var LeadFields = [];
              LeadFields.push({
                Attribute: "FirstName",
                Value: values.name,
              });
              LeadFields.push({
                Attribute: "EmailAddress",
                Value: values.email,
              });
              LeadFields.push({
                Attribute: "mx_Portal_Password",
                Value: values.password,
              });
              LeadFields.push({
                Attribute: "mx_Portal_Confirm_Password",
                Value: values.password,
              });
              LeadFields.push({
                Attribute: "Mobile",
                Value: values.mobile,
              });
              LeadFields.push({
                Attribute: "Company",
                Value: values.company,
              });
              LeadFields.push({
                Attribute: "mx_Lead_Type",
                Value: "Referrer",
              });
              Register(
                {
                  LeadFields: LeadFields,
                },
                {
                  changeState: this.changeState,
                  resetForm: formikActions.resetForm,
                  props: this.props,
                }
              );
            }}
          >
            {(props) => {
              const { isSubmitting } = props;
              // console.log(isSubmitting);
              return (
                <Form className="form-container">
                  {this.state.formError ? this.state.formError : null}
                  <div className="form-group">
                    <label htmlFor="name">
                      Name
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                    <Field
                      type="text"
                      placeholder="Name"
                      autoComplete="false"
                      name="name"
                    ></Field>
                    <div className="errorbox">
                      <ErrorMessage name="name" className="errorDiv" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="company">
                      Company
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                    <Field
                      type="text"
                      placeholder="Company"
                      autoComplete="false"
                      name="company"
                    ></Field>
                    <div className="errorbox">
                      <ErrorMessage name="company" className="errorDiv" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobilenumber">
                      Mobile Number
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                    <Field
                      type="number"
                      placeholder="Mobile Number"
                      name="mobile"
                    ></Field>
                    <div className="errorbox">
                      <ErrorMessage name="mobile" className="errorDiv" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                    <Field
                      type="email"
                      placeholder="Email Addresss"
                      autoComplete="false"
                      name="email"
                    ></Field>
                    <div className="errorbox">
                      <ErrorMessage name="email" className="errorDiv" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password ">
                      Password
                      <sup>
                        <span className="star-color pl-lg-1">.</span>
                      </sup>
                    </label>
                    <Field
                      autoComplete="new-password"
                      type="password"
                      placeholder="Password"
                      name="password"
                    ></Field>
                    <div className="errorbox">
                      <ErrorMessage name="password" className="errorDiv" />
                    </div>
                  </div>

                  <div className="">
                    <button
                      type="submit"
                      disabled={this.state.isSubmitting}
                      className="fbtn   mt-2 golden-button"
                    >
                      {this.state.isSubmitting ? "Loading..." : "Sign Up"}
                    </button>
                  </div>
                  <div className="text-center w-100 mt-4 d-flex justify-content-center">
                    Already have an Account?
                    <div
                      className="golden-text"
                      onClick={() => {
                        this.props.history.push("/login");
                      }}
                    >
                      Login
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUpForm);

function GetVaidationSchema() {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    company: yup.string().required("Required"),
    email: yup.string().email("Invalid Email").required("Required"),
    password: yup
      .string()
      .min(4, "Please choose a password with at least 4 characters ")
      .required("Required"),

    mobile: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
  });
  return validationSchema;
}
