import React from "react";
import { withRouter, Link } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../images/estateone logo-01 (2).png";
import "../../css/scss/Header.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // console.log("render forgot");
    return (
      <>
        <div className="HeaderBanner1">
          <div className="HeaderBanner2">
            <div className="HeaderContainer">
              <div className="HeaderContainer1">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className="HeaderContainer2">
                <nav role="full-horizontal">
                  <ul>
                    <li>
                      <li>
                        <a href="/signup">Sign Up</a>
                      </li>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="HeaderCo">
              <div className="HeaderContainer3">
                <Navbar>
                  <NavDropdown
                    title={<i class="fas fa-bars"></i>}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                    <NavDropdown.Item href="/signup">Sign Up</NavDropdown.Item>
                  </NavDropdown>
                </Navbar>
              </div>
              <div className="HeaderContainer4">
                <Link to="/">
                  <img src={logo} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Header);
