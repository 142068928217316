import React from "react";
import SessionChecker from "../sesionChecker";
import "bootstrap/dist/css/bootstrap.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../css/LandingPage.css";
import "../../css/scss/LandingPage.scss";
import "../../css/Form.css";
import Header from "./Header";
import tick from "../../images/bule-tick.svg";
import e1 from "../../images/e1.png";
import e2 from "../../images/e2.png";
import e3 from "../../images/e3.png";
import e4 from "../../images/e4.png";
import e5 from "../../images/e5.png";
import img2 from "../../images/Mask Group 58.png";
import Typesexperts from "./Typesexperts";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    AOS.init();
  }

  render() {
    const alt = "Refferal portal";
    return (
      <div>
        <Header />
        <div className="Connecting-Communities">
          <div className="Banner">
            <div className="Card">
              <div className="Card1">
                <p className="Card1text1">
                  Estate.One - Connecting Communities
                </p>
                <p className="Card1text2">
                  Estate.one experts are knowledgeable about some or many
                  aspects of processes involved in connecting to communities.
                  That means, experts enable and empower people connecting to
                  their communities.
                </p>
                <p className="Card1text2">
                  Expert engagement at esate.one is always a blended engagement
                  to empower end customers. As estate.one is tech and
                  information driven, the portal allows people to upload basic
                  information about their customers in a secured manner. End
                  customers will engage with the content at their own pace. When
                  they are stuck somewhere or need guidance or help, then they
                  will request a call back. Then the appropriate expert will
                  interact with them with historical knowledge of transaction.
                  This process continues and this blended engagement gives
                  comfort to both the experts and their customers.
                </p>
              </div>
              <div className="Card2">
                <img src={img2} alt="card"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="end-customers">
          <div className="Why_Ref_prog bgcolor pt-lg-5 container-fluid pt-64 pb-90 end-customers">
            <div className="container">
              <div className="text-center l-title mb-5 pt-5" data-aos="fade-in">
                <h3>Advantages of blended engagement to end customers</h3>
                <div
                  className="d-md-flex listBox"
                  style={{ overflow: "hidden" }}
                >
                  <div className="agent-box">
                    <ul>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Customers are the focal point
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Interactions happen at customer pace and comfort. It
                        could be very small information to detailed
                        person-person meeting with an expert.
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Customer is empowered with information and decision
                        support systems
                      </li>
                    </ul>
                  </div>

                  <div className="agent-box">
                    <ul>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Realtime feedback about interactions will help improve
                        the customer experience
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Customers are never forced to take decisions but are
                        empowered to take right decision for them.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Advantage-experts">
          <div className="Why_Ref_prog bgcolors pt-lg-5 container-fluid pt-64 pb-90 end-customers">
            <div className="container">
              <div className="text-center l-title mb-5 pt-5" data-aos="fade-in">
                <h3>Advantages for experts</h3>
                <div
                  className="d-md-flex listBox"
                  style={{ overflow: "hidden" }}
                >
                  <div className="agent-box">
                    <ul>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Customer is the focal point. Your customer is treated
                        well.
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Collaborative interactions. When customer wants to
                        interact and you are not available or do not have enough
                        information to answer, another expert will come and help
                        your customers to empower them. This kind of
                        collaboration will help them make the decision making
                        faster.
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Bridging the digital divide: Both experts and customers
                        are fed with digital information such as basic
                        information or augmented realty platform to give more
                        interactive experience at figure tips.
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Organized will grow (and unorganized will vanish):
                        Estate.one offers automatic workflows and facilitate
                        integrations to organize the timely information flow.
                        Every stakeholder will be benefited with this.
                      </li>
                      <li>
                        <img src={tick} alt={alt} className="pr-3" />
                        Enable work-life balance: Expert can choose when, where
                        and how long he or she can work and earn for
                        gig-transactions. This enables work-life balance.
                      </li>
                    </ul>
                  </div>
                  <div className="agent-box">
                    <ul></ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Types-experts">
          <div className="Banner">
            <p className="heading">Types of experts</p>
            <Typesexperts />
          </div>
        </div>

        <footer className="footer">
          <div className="container d-flex">
            <span>&copy; Copyright 2020 - Estate.one</span>
          </div>
        </footer>
      </div>
    );
  }
}
export default LandingPage;
