import React from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import utilityFunction from "../api-caller/utility";
import utility from "../api-caller/utility";
import { API_GetLeadsById } from "../api-caller/apiCaller";
import LoadingScreen from "../Loader/Loader";
import "./style.css";

/* global lsq_setupForm */
class KYCForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadId: null,
      loading: true,
    };
    this.loadOverrideCSS = this.loadOverrideCSS.bind(this);
    this.onLSQFormSubmissionSuccess = this.onLSQFormSubmissionSuccess.bind(
      this
    );
  }

  loadOverrideCSS() {
    this.setState({ loading: false });
  }

  componentDidMount() {
    let leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    const leadP = !!leadId;
    // if session expire redired user to login page
    if (!leadP) {
      console.log("auth session already expired ");
      this.props.history.push("/");
    }

    API_GetLeadsById(utility.GetLeadId())
      .then((data) => data.json())
      .then((data) => {
        const kycUplaoded =
          data && !!data[0].mx_Aadhar_Number && !!data[0].mx_PAN;
        // console.log(kycUplaoded);
        if (kycUplaoded) {
          window.location.href = "/Dashboard";
        } else {
          // console.log('leadid', this.state.leadId);
          let devFormurl =
            "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=61.4.0.10";

          $.getScript(devFormurl)
            .done(function (data) {
              lsq_setupForm({
                id: "1af15a9c-ab1a-11ea-bf5b-0a22ff7e4b86",
                authKeyProvider: utilityFunction.GetAuthKey(),
              });
            })
            .fail(function (error) {});

          this.lsqFormContainer.addEventListener(
            "lsqformloadcomplete",
            this.loadOverrideCSS
          );
          this.lsqFormContainer.addEventListener(
            "lsqformsubmissionsuccess",
            this.onLSQFormSubmissionSuccess
          );
          this.lsqFormContainer.addEventListener(
            "LSQFormLoadError",
            this.onLSQFormLoadError
          );
        }
      })
      .catch((error) => {
        window.location.href = "/Dashboard";
      });
  }

  onLSQFormSubmissionSuccess() {
    window.location.href = "/KYCThankYou";
  }
  onLSQFormLoadError(e) {
    //Expire cookie
    utilityFunction.ExpireAuthSessionInfoCookie();
    //log out
    window.location.href = "/";
  }

  render() {
    // console.log(this.props);
    let actualAuthKey = utilityFunction.GetAuthKey();
    let actualFormId = "1af15a9c-ab1a-11ea-bf5b-0a22ff7e4b86";

    return (
      <div className="kyc-form-container">
        {this.state.loading && <LoadingScreen />}
        <div
          id="lsq-form-modal"
          ref={(elem) => (this.lsqFormContainer = elem)}
          data-form-id={actualFormId}
          class="modal-v4 fullscreen external lsq-external-form-container"
        >
          <div class="lsq-form-container-wrapper"></div>
          <div class="lsq-form-hidden-fields">
            <input
              id="lsq-authKey"
              name="lsq-authKey"
              type="hidden"
              value={actualAuthKey}
            />
            <input
              id="lsq-api-service-url"
              name="lsq-api-service-url"
              type="hidden"
              value="https://portalapi-in21.leadsquared.com/api/Form"
            />
            <input
              id="lsq-app-url"
              name="lsq-app-url"
              type="hidden"
              value="https://in21.leadsquared.com"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(KYCForm);
