import React from "react";
import "./css/App.css";
import { BrowserRouter } from "react-router-dom";
import Routing from "./route";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </div>
  );
}

export default App;
