import React from "react";
import { withRouter, Link } from "react-router-dom";
import logo from "../../images/estateone.png";
import GetUsername from "./GetUsername";
import utility from "../api-caller/utility";

import $ from "jquery";

var alt = "ReferralPortal";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
    this.state = {
      username: "",
    };
  }
  changeState(stateInfo) {
    this.setState(stateInfo);
  }
  componentDidMount() {
    var leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    // if session expire redired user to login page
    if (!leadId) {
      // console.log("auth session already expired ");
      this.props.history.push("/");
    }
    GetUsername(leadId, { changeState: this.changeState }, this.props);

    $("#setting").popover({
      html: true,
      placement: "bottom",
    });
    $("body").on("click", function (e) {
      $('[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (
          !$(this).is(e.target) &&
          $(this).has(e.target).length === 0 &&
          $(".popover").has(e.target).length === 0
        ) {
          $(this).popover("hide");
        }
      });
    });
  }
  render() {
    return (
      <div className="container-fluid header">
        <div className="container pl-0 pr-0  pl-lg-3 pr-lg-3 d-flex justify-content-between ">
          <div className="logo-container">
            <Link to="/Dashboard">
              <img src={logo} alt={alt}></img>
            </Link>
          </div>

          <div
            className="d-flex username-container"
            style={{ cursor: "pointer" }}
          >
            <span
              className="float-right d-flex "
              data-toggle="popover"
              data-placement="bottom"
              id="setting"
              style={{ top: "-16px" }}
              data-content="<div ><a href='/SessionManager' id='setting-menu'>Logout</a></div>"
            >
              <div className="avatar-img">
                <i
                  className="fas  fa-2x fa-user-circle pr-2 "
                  style={{ lineHeight: "72px" }}
                ></i>
              </div>
              <div className="username d-none d-md-block">
                {this.state.username}
              </div>
              <i
                className="fa fa-angle-down"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
