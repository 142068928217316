import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import SessionManager from "./components/SessionManager";
import KYC from "./components/KYC";
import KYCThankYou from "./components/KYC/ThankYou";
// import Error404 from "./components/error-404/Error404";
import LandingRoute from "./LandingRoute";
import utility from "./components/api-caller/utility";

class Routing extends Component {
  constructor(props) {
    super(props);
    this.Routes = [
      {
        path: "/Dashboard",
        exact: true,
        component: Dashboard,
        title: "Dashboard",
      },
      {
        path: "/KYCThankYou",
        exact: true,
        component: KYCThankYou,
        title: "KYCThankYou",
      },
      {
        path: "/SessionManager",
        exact: true,
        component: SessionManager,
        title: "SessionManager",
      },
      {
        path: "/KYC",
        exact: true,
        component: KYC,
        title: "KYC",
      },
      {
        path: "/",
        exact: false,
        component: LandingRoute,
        title: "LandingRoute",
      },
    ];
  }

  isAllowed = (props, RouteComponent, title) => {
    if (this.isCurrentUser()) {
      return <RouteComponent {...props} />;
    } else {
      if (title === "LandingRoute") {
        return <LandingRoute {...props} />;
      } else {
        return <Redirect to={"/"} />;
      }
    }
  };

  isCurrentUser = () => {
    const Auth = utility.GetAuthSessionInfoCookieValue("authSessionState")
      ? utility.GetAuthSessionInfoCookieValue("authSessionState")
          .isAuthenticated
      : false;
    return Auth;
  };

  render() {
    return (
      <div>
        <Switch>
          {this.Routes.map((route, i) => (
            <Route
              key={i}
              exact={route.exact}
              path={route.path}
              render={(props) =>
                this.isAllowed(props, route.component, route.title)
              }
            />
          ))}
        </Switch>
      </div>
    );
  }
}

export default Routing;
