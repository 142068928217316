var Constants = {
  PortalAPI: {
    baseURL: 'https://portalapi-in21.leadsquared.com/',
    endPoints: {
      Signin: 'api/Authentication/Signin',
      Register: 'api/Authentication/Register',
      ForgotPassword: 'api/Authentication/ForgotPassword',
      ChangePassword: 'api/Settings/ChangePassword',
      Signout: 'api/Authentication/Signout',
      ResetPassword: 'api/Authentication/ResetPassword'
    }
  },
  LSQAPI: {
    baseURL: 'https://api-in21.leadsquared.com/v2/',
    endPoints: {
      GetLeadsById: 'LeadManagement.svc/Leads.GetById',
      GetLeadActivity: 'ProspectActivity.svc/Retrieve',
      UpdateActivity: 'ProspectActivity.svc/CustomActivity/Update',
      GetActivityDetailsById: 'ProspectActivity.svc/GetActivityDetails',
      GetLeadByFieldDetail: 'LeadManagement.svc/Leads.Get'
    }
  }
};

export default Constants;
