import { MDBDataTable } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import utility from "../api-caller/utility";
import config from "../config/index";
import Constants from "../constants/index";
import { withRouter } from "react-router-dom";
//quick start to mdbreact https://mdbootstrap.com/docs/react/getting-started/quick-start/
import React, { Component } from "react";
import { API_GetLeadsById } from "../api-caller/apiCaller";
import Spinner from "../Spinner";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      won: 0,
      lost: 0,
      prospect: 0,
      showSpinner: true,
      showKYC: true,
    };
  }
  componentDidMount() {
    // console.log(new Date().toLocaleString());

    var leadId = utility.IsAuthSessionInfoCookiePresent
      ? utility.GetAuthSessionInfoCookieValue("authSessionState").leadId
      : this.props.history.push("/");
    // if session expire redired user to login page
    if (!leadId) {
      // console.log('auth session already expired ');
      this.props.history.push("/");
    }
    // console.log('leadid ', leadId);
    var data = {
      Parameter: {
        LookupName: "mx_ReferrerLeadID",
        LookupValue: leadId,
        SqlOperator: "=",
      },
      Columns: {
        Include_CSV:
          "ProspectID, FirstName , LastName , EmailAddress, mx_Project_Name, mx_House_Type, mx_Other_House_Type,ProspectStage,Mobile,CreatedOn",
      },
      Sorting: {
        ColumnName: "CreatedOn",
        Direction: "1",
      },
      Paging: {
        PageIndex: 1,
        PageSize: 100,
      },
    };
    fetch(
      Constants.LSQAPI.baseURL +
        Constants.LSQAPI.endPoints.GetLeadByFieldDetail +
        "?accessKey=" +
        config.AccessKey +
        "&secretKey=" +
        config.SecretKey,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => Response.json())
      .then((json) => {
        this.props.props.changeState({ rowCount: json.length });
        API_GetLeadsById(utility.GetLeadId())
          .then((data) => data.json())
          .then((data) => {
            const kycUplaoded = !(
              data &&
              !!data[0].mx_Aadhar_Number &&
              !!data[0].mx_PAN
            );
            this.setState({ showSpinner: false, showKYC: kycUplaoded });
            // $('body').css('overflow-y', 'scroll');

            // console.log("get lead details ", json);

            json.forEach((row) => {
              var lastname = row.LastName
                ? row.LastName.charAt(0).toUpperCase() + row.LastName.slice(1)
                : "";
              var firstname = row.FirstName
                ? row.FirstName.charAt(0).toUpperCase() + row.FirstName.slice(1)
                : "";
              row.mx_Fullname = firstname + " " + lastname;
              row.mx_Project_Name = row.mx_Project_Name
                ? row.mx_Project_Name
                : " ";
              row.EmailAddress = row.EmailAddress ? row.EmailAddress : " ";
              row.mx_House_Type =
                row.mx_House_Type &&
                (row.mx_House_Type === "Others"
                  ? row.mx_Other_House_Type
                  : row.mx_House_Type);
              row.ProspectStage = row.ProspectStage ? row.ProspectStage : " ";
              row.Mobile = row.Mobile ? row.Mobile : " ";
              row.CreatedOn = row.CreatedOn ? row.CreatedOn.split(" ")[0] : " ";
            });

            // console.log('succ', json);
            for (var i = 0; i < json.length; i++) {
              const state = json[i].ProspectStage.toLowerCase();
              if (state === "prospect" || state === "opportunity") {
                json[i].status = (
                  <div style={{ color: "#6142C9", fontWeight: "400" }}>
                    {json[i].ProspectStage}
                  </div>
                );
                this.setState({ prospect: this.state.prospect + 1 });
              } else if (state === "customer") {
                json[i].status = (
                  <div style={{ color: "#24B47E", fontWeight: "400" }}>
                    {json[i].ProspectStage}
                  </div>
                );

                this.setState({ won: this.state.won + 1 });
              } else {
                json[i].status = (
                  <div style={{ color: "#FF3636", fontWeight: "400" }}>
                    {json[i].ProspectStage}
                  </div>
                );
                this.setState({ lost: this.state.lost + 1 });
              }
            }

            var data1 = {
              columns: [
                {
                  label: "Full name",
                  field: "mx_Fullname",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "Stage",
                  field: "status",
                  sort: "asc",
                  width: 120,
                },
                {
                  label: "Email Address",
                  field: "EmailAddress",
                  sort: "asc",
                  width: 180,
                },

                {
                  label: "Project Name",
                  field: "mx_Project_Name",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: " House Type",
                  field: "mx_House_Type",
                  sort: "asc",
                  width: 120,
                },

                {
                  label: "Mobile Number",
                  field: "Mobile",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "Created On",
                  field: "CreatedOn",
                  sort: "asc",
                  width: 120,
                },
                {
                  label: "State",
                  field: "ProspectStage",
                  sort: "asc",
                  width: 120,
                },
              ],
              rows: json,
            };

            this.setState({ data: data1 });
          })
          .catch((error) => {
            // console.log(error, "error");
            alert("Something went wrong!!!");
          });
      });

    // console.log(
    //   'what ',
    //   this.props.props.changeState({ rowCount: data.rows.length }),

    // );
  }

  pageRouting = () => {
    window.location.href = "/KYC";
  };
  render() {
    // console.log("state change", this.state);
    if (this.state.showSpinner)
      return <Spinner show={this.state.showSpinner} />;
    return (
      <div className="container">
        <div
          className="d-md-flex justify-content-between tableHeader"
          style={{ marginTop: "30px" }}
        >
          <span
            className="heading"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            My Referrals
          </span>{" "}
          <div className="d-md-flex key-button-wrapper">
            {this.state.showKYC && (
              <div
                className="midsize-btn m-3 addKyc"
                onClick={this.pageRouting}
              >
                Add KYC
              </div>
            )}
            <div
              className="midsize-btn m-3 "
              style={{ margin: "unset" }}
              onClick={() => this.props.props.showForm()}
            >
              Add New Referrals
            </div>
          </div>
        </div>

        {/* <hr /> */}
        <div
          className="row justify-content-between status-cards"
          style={{ marginTop: "30px", marginBottom: "16px" }}
        >
          <div className="col-4 text-center ca">
            <div
              className="card card-body prospect p-1 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div style={{ color: "#6142C9" }}>Prospect</div>
              <div>{this.state.prospect}</div>
            </div>
          </div>
          <div className="col-4 text-center ca">
            <div
              className="card card-body won p-1 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div style={{ color: "#24B47E" }}>Won</div>
              <div>{this.state.won}</div>
            </div>
          </div>
          <div className="col-4 text-center ca">
            <div
              className="card card-body lost p-1 pt-4 pb-4"
              data-aos="zoom-in"
            >
              <div style={{ color: "#FF3636" }}>Lost</div>
              <div>{this.state.lost}</div>
            </div>
          </div>
        </div>

        {/* <hr /> */}
        <div data-aos="zoom-in">
          <MDBDataTable
            // maxHeight='40vh'
            scrollX
            striped
            bordered
            data={this.state.data}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(DataTable);
