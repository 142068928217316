import React from "react";
import SessionChecker from "../sesionChecker";
import "bootstrap/dist/css/bootstrap.css";
import ResetForm from "./ResetForm";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../css/LandingPage.css";
import "../../css/Form.css";
import logo from "../../images/estateone.png";
import img1 from "../../images/banner-image.png";
import tick from "../../images/bule-tick.svg";
import "../../css/scss/ResetPassword.scss";
import Header from "../landing-page/Header";
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }
  render() {
    const alt = "Refferal portal";
    return (
      <div className="Resetcard">
      <Header/>
      <div className="InnerCard">
            <ResetForm />
         
      </div>
      </div>
    );
  }
}
export default LandingPage;
