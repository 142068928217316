import React from "react";
import e1 from "../../images/e1.png";
import e2 from "../../images/e2.png";
import e3 from "../../images/e3.png";
import e4 from "../../images/e4.png";
import e5 from "../../images/e5.png";

class Typesexperts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            experts: [{
                id: 1,
                img: e1,
                heading: "Lead expert or Referrer",
                text1: " When you want your friends/family members or your customers to connect with a right community that addresses their needs, all you do is refer them in a secured manner. They will be fed with long list of info-bits and empower them through the process of learning/exploration at their own pace and enable them to take a right decision.",
                text2: "When this referee buys anything (such as a house) or uses a service (such as taking a loan), referrer will be paid automatically provided necessary forms are filled.",
            },
            {
                id: 2,
                img: e2,
                heading: "Call expert",
                text1: "Some people with extraordinary communication skills, those can facilitate telephone communication and address customer concerns or facilitate them, are qualified to be call experts. They are paid for time spent and based on quality of interactions.",
                text2: "",
            },
            {
                id: 3,
                img: e3,
                heading: "Sales expert",
                text1: "Sales expert is a seasoned real-estate expert who wants to work in gig-economy. Estate.one gives training about a project/location and related information and enables them to help end-customers to connect to the right community.",
                text2: ""
            },
            {
                id: 4,
                img: e4,
                heading: "Site expert",
                text1: "These are typically are trained to be at a site who will be trained in-and-out about the site, location, master plans, plans etc.",
                text2: ""
            },
            {
                id: 5,
                img: e5,
                heading: "Super expert",
                text1: "These are typically people with managerial skills who help other experts to organize/perform roles and responsibilities. These also help process improvements to enhance customer experience.",
                text2: ""
            }]
        }
    }
    renderSection=(obj)=>{
        if(obj.id%2==0)
        {
            return(<div className="experts2" key={obj.id}>
            <div className="Card">
                <div className="Card2">
                    <img src={obj.img} alt="card"></img>
                </div>
                <div className="Card1">
                    <p className="Card1text1">{obj.heading}</p> <br />
                    <p className="Card1text2">
                    {obj.text1} 
</p>
                </div>
            </div>
        </div>)
        }else{
            return(<div className="experts1" key={obj.id}>
            <div className="Card">
                <div className="Card1">
                    <p className="Card1text1">{obj.heading}</p> <br />
                    <p className="Card1text2">
                      {obj.text1}
</p>
                    <br />
                    <p className="Card1text2">
                    {obj.text2}
</p>
                </div>
                <div className="Card2">
                    <img src={obj.img} alt="card"></img>
                </div>
            </div>
        </div>)
        
        }
    }
    render() {
      return(
        <>
         {this.state.experts.map((expert, id) => (
             <>
                  
                  {this.renderSection(expert)}
                    
                    </>   
                
          ) )}
            </>
      )
    }

}

export default Typesexperts;

