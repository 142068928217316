import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Register, Login, ForgotPasswordAPI } from "../api-caller/apiCaller";
import { withRouter } from "react-router-dom";
import Header from "./Header";
import "../../css/scss/ForgotPassword.scss";
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: "",
      isSubmitting: false,
    };
    this.changeState = this.changeState.bind(this);
  }
  changeState(stateInfo) {
    this.setState(stateInfo);
    this.setState({ isSubmitting: false });
  }
  render() {
    // console.log("render forgot");
    return (
      <div className="Forgotcard">
        <Header />
        <div className="InnerCard">
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values, formikActions) => {
              this.setState({ isSubmitting: true });
              // console.log('inside forgot submit ', values, formikActions);
              ForgotPasswordAPI(
                {
                  EmailAddress: values.email,
                },
                {
                  changeState: this.changeState,
                  resetForm: formikActions.resetForm,
                  props: this.props,
                }
              );
            }}
            validationSchema={GetForgotVaidationSchema}
          >
            <Form className="form-container">
              <p className="tt">Forgot Password</p>
              <p className="text-heading">
                Please enter your email, We will send a password reset link.
              </p>
              <div className="text-center">
                {this.state.formError ? this.state.formError : null}
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  Email
                  <sup>
                    <span className="star-color pl-lg-1">.</span>
                  </sup>
                </label>
                <Field
                  type="text"
                  placeholder="Email Addresss"
                  autoComplete="false"
                  name="email"
                ></Field>
                <div className="errorbox">
                  <ErrorMessage name="email" className="errorDiv" />
                </div>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  disabled={this.state.isSubmitting}
                  className="fbtn   golden-button"
                >
                  {this.state.isSubmitting
                    ? "Loading..."
                    : "Send Password Reset Link"}
                </button>
              </div>
              <div className="form-group d-flex justify-content-center mt-24 mb-0">
                <span>Already have an Account?</span>
                <a href="/login" className="golden-text">
                  Login
                </a>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);

function GetForgotVaidationSchema() {
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid Email").required("Required"),
  });
  // console.log('vlaidlogin', validationSchema);
  return validationSchema;
}
